import './App.css';
import { useState } from 'react';
import axios from 'axios';

function App() {
  const [data, setData] = useState({});

  const handleChange =(event) => {
    const name=event.target.name;
    const value=event.target.value;
    setData(values => ({...values, [name]:value }));
  }

  const handleClick =(event) => {
    event.preventDefault();
    const url='http://localhost:8080/api/kullanici/kaydet';
    axios.post(url,data);
    console.log(data);

  }
  return (
    
    <div className="App">
      <h3>
        Veri Girişini Buradan Yapınız..
      </h3>
      <form action="" method='' onSubmit={handleClick}>
      <table>
        <tbody>
        <tr>
            <td>İsim :</td>
            <td>
              <input type="text" name='isim'  placeholder='İsim Giriniz..' onChange={handleChange}/>
            </td>
          </tr>
          <tr>
            <td>Email :</td>
            <td>
              <input type="text" name='email' placeholder='Emailinizi Giriniz..' onChange={handleChange}/>
            </td>
          </tr>
          <tr>
            <td>Telefon :</td>
            <td>
              <input type="text" name='telefon' placeholder='Telefon Numaranızı Giriniz..' onChange={handleChange}/>
            </td>
          </tr>
          <tr>
            
            <td>
              <button>kaydet</button>
            </td>
          </tr>
        </tbody>
          
        </table>
        </form>
    </div>
  );
}

export default App;
